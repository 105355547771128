import { useMemo } from 'react';
import { MdOutlineWarningAmber, MdErrorOutline } from 'react-icons/md';
import {
  Button,
  Checkbox,
  DataGridEditableCellOverrideParams,
  DataGridRenderBodyCellParams,
  Popover,
  Store,
  StorePopover,
} from '@dierbergs-markets/react-component-library';
import { IReferenceDataState } from '../../../../../../contexts/ApplicationContext';
import { defaultColors } from '../../../../../../styles/variables';
import { format } from 'date-fns';
import { ValidationIssue } from '../../../../../../classes/ValidationIssue';
import { ValidationSeverity } from '../../../../../../models/enums';
import { IContract, IPricing } from '../../../../../../models';
import {
  computeCaseCostForRow,
  computeUnitCostForRow,
  getContractPricingItems,
  getCostImplicationType,
  PricingGridPricingItem,
} from '../utilities/PricingUtilities';
import { PricingGridInternalValidationIssueType } from '@dierbergs-markets/react-feature-library';
import * as PG from '@dierbergs-markets/react-feature-library';
import { tss } from 'tss-react';

/**
 * A hook that processes contract pricing data into structured rows and columns for the pricing grid.
 *
 * This hook transforms raw contract and pricing data into the formatted structure required
 * by the pricing grid component, handling:
 *
 * - Generation of pricing item rows with calculated costs and metadata
 * - Creation of column configurations with proper header content and formatting
 * - Calculation of case and unit costs based on term implications
 * - Preparation of validation indicators and interactive elements
 * - Formatting of pricing data for display with proper styling and behavior
 *
 * @param contract - The contract containing pricing data to process
 * @param referenceData - Reference data state containing lookup values
 * @param config - Additional configuration options for customizing the output
 * @returns Object containing formatted pricing grid rows and column configurations
 *
 * @example
 * const { pricingColumnConfigurations, pricingItems } = useContractHubPricingGridRowsAndColumns(
 *   contract,
 *   referenceData,
 *   { canModify: true, invokePricingEdit }
 * );
 */
export default function useContractHubPricingGridRowsAndColumns(
  contract?: IContract,
  referenceData?: IReferenceDataState,
  config?: {
    canModify?: boolean;
    invokePricingEdit?: (pricing: IPricing) => void;
    updatePricing?: (pricingIndex: number, pricing: IPricing) => void;
    pricingConfigurationValidationIssues?: ValidationIssue[];
  }
) {
  const { css, classes } = useStyles();
  const { canModify = true, invokePricingEdit, updatePricing, pricingConfigurationValidationIssues } = config || {};

  const pricingItems = useMemo(() => {
    return getContractPricingItems(contract, referenceData);
  }, [contract, referenceData]);

  const pricingColumnConfigurations = useMemo(() => {
    if (!contract || !contract.pricings || !referenceData) return [];

    return contract.pricings.map<PG.IPricingColumnConfiguration<PricingGridPricingItem>>((pricingConfig, pricingConfigIndex) => {
      const pricingValidationIssues = pricingConfigurationValidationIssues?.filter((issue) => issue.identifier === pricingConfigIndex) || [];
      const hasErrors = pricingValidationIssues.some((issue) => issue.severity === ValidationSeverity.Error);
      const hasWarnings = pricingValidationIssues.some((issue) => issue.severity === ValidationSeverity.Warning);
      try {
        return {
          pricingAreas: contract?.pricings ? contract.pricings[pricingConfigIndex].areas.map((area) => area.stores) : [],
          name: getPricingName(pricingConfig),
          priceType: pricingConfig.priceTypeId,
          startDate: pricingConfig.startDate,
          endDate: pricingConfig.endDate,
          comments: pricingConfig.comments,
          commonStoreGroupHeader: {
            template: () => <div></div>,
            cssOverrides: {
              root: {
                paddingTop: '8px',
                height: '80px',
              },
            },
          },
          caseCost: (row: PricingGridPricingItem) => computeCaseCostForRow(row, pricingConfig, referenceData),
          unitCost: (row: PricingGridPricingItem) => computeUnitCostForRow(row, pricingConfig, referenceData),
          additionalSubColumns: [
            {
              field: `orderSurvey_${pricingConfigIndex}`,
              type: 'boolean',
              columnBodyCss: {
                alignItems: 'center',
              },
              width: 85,
              editable: true,
              hideable: false,
              editableCellOverride: (params: DataGridEditableCellOverrideParams<number, PricingGridPricingItem>) => {
                if (params.row.rowType === 'Parent') {
                  return {
                    isEditable: true,
                    cellCss: {
                      backgroundColor: defaultColors.editGreen,
                      '& div[role=checkbox]': {
                        justifyContent: 'center',
                      },
                    },
                  };
                } else {
                  return {
                    isEditable: false,
                    cellCss: {
                      backgroundColor: 'inherit',
                      borderBottom: `1px solid ${defaultColors.grey}`,
                    },
                  };
                }
              },
              renderHeaderCellContent: () => {
                if (!contract.pricings) return;

                function haveSameNumbers(arr1: number[], arr2: number[]): boolean {
                  const set1 = new Set(arr1);
                  const set2 = new Set(arr2);
                  if (set1.size !== set2.size) return false;
                  return [...set1].every((num) => set2.has(num));
                }

                const allSkus = contract.terms.contractItems.map((item) => item.sku);

                //Determine if Select All checkbox intial checked state for this pricing.
                const checkBoxChecked =
                  pricingConfig.orderSurveyItemSkus.length > 0 && allSkus.length > 0 && haveSameNumbers(pricingConfig.orderSurveyItemSkus, allSkus);

                return (
                  <Checkbox
                    id={`selectAll_pricing${pricingConfigIndex}`}
                    label={'Order Survey'}
                    initialValue={checkBoxChecked}
                    checked={checkBoxChecked}
                    disabled={!canModify}
                    onChange={handleSelectionChange}
                  />
                );

                function handleSelectionChange(checked: boolean) {
                  const selections = checked ? [...new Set(allSkus)] : [];
                  updatePricing?.(pricingConfigIndex, { ...pricingConfig, orderSurveyItemSkus: selections });
                }
              },
              renderBodyCellContent: (params: DataGridRenderBodyCellParams<(boolean | undefined)[], PricingGridPricingItem>) => {
                if (params.row.rowType === 'Parent') {
                  return (
                    <Checkbox
                      id={`selectAll_pricing${pricingConfigIndex}`}
                      initialValue={pricingConfig.orderSurveyItemSkus.includes(params.row.item.sku)}
                      checked={pricingConfig.orderSurveyItemSkus.includes(params.row.item.sku)}
                      disabled={!canModify}
                      onChange={(checked) => {
                        const newOrderSurveyItemSkus = checked
                          ? [...new Set([...pricingConfig.orderSurveyItemSkus, params.row.item.sku])]
                          : pricingConfig.orderSurveyItemSkus.filter((sku) => sku !== params.row.item.sku);

                        updatePricing?.(pricingConfigIndex, { ...pricingConfig, orderSurveyItemSkus: newOrderSurveyItemSkus });
                      }}
                    />
                  );
                } else '';
              },
            },
          ],
          groupedHeaderCellCss: {
            root: {
              marginTop: '10px',
              height: '150px',
              backgroundColor: defaultColors.white,
              borderRadius: '16px 16px 0 0',
              outline: `1px solid ${defaultColors.grey}`,
              fontSize: '13px',
              color: defaultColors.mediumGrey,
            },
            subCell: {
              borderTop: 'unset',
            },
          },
          renderHeaderCellContent: () => {
            const adLocation = referenceData.adSites.all.find((a) => a.adSiteId === pricingConfig.adSiteId);
            const btnText = `${referenceData.priceTypes.byId[pricingConfig.priceTypeId].name} ${adLocation ? ` - ${adLocation.name}` : ''}`;
            return (
              <div className={classes?.headerContents}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '25px' }}>
                  <Button
                    classes={{ root: classes?.headerEditBtn }}
                    id={`editPricing${pricingConfig.uniqueId}`}
                    onClick={() => invokePricingEdit?.(pricingConfig)}
                    variant={'link'}
                    title={btnText}
                    text={btnText}
                  />
                  {hasErrors && (
                    <MdErrorOutline
                      style={{ color: defaultColors.red, marginLeft: '5px', fontSize: '16px' }}
                      title={pricingValidationIssues
                        .filter((i) => i.severity === ValidationSeverity.Error)
                        .map((i) => i.message)
                        .join('\n')}
                    />
                  )}
                  {hasWarnings &&
                    pricingValidationIssues.some(
                      (i) =>
                        i.severity === ValidationSeverity.Warning &&
                        i.type === PricingGridInternalValidationIssueType.RegularPriceStoreAreaPricingStoreAreaMismatch
                    ) && (
                      <Popover
                        id={`warningPoppover_${pricingConfigIndex}`}
                        title="View Warning"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        classes={{
                          popoverContent: css({ width: '600px' }),
                        }}
                        buttonProps={{
                          variant: 'icon',
                          children: <MdOutlineWarningAmber style={{ color: defaultColors.orange, fontSize: '16px' }} />,
                        }}
                      >
                        <div>
                          <div style={{ marginBottom: '10px' }}>
                            This pricing has the following store group(s) associated with it:
                            <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '4px' }}>
                              {pricingConfig.areas.map((area, areaIndex) => {
                                const stores = area.stores.map<Store>((storeId) => ({
                                  number: storeId,
                                  name: referenceData.stores.byId[storeId].displayName,
                                }));
                                return <StorePopover stores={stores} id={`storePoppover_${areaIndex}`} key={areaIndex} />;
                              })}
                            </span>
                          </div>
                          <div>
                            {pricingConfig.areas.length > 1
                              ? 'Its groups do not match the current regular price stores for the items on the contract.'
                              : 'Its group does not match the current regular price stores for the items on the contract.'}
                          </div>
                        </div>
                      </Popover>
                    )}
                </div>
                <div>
                  {format(pricingConfig.startDate, 'M/d/yy')} - {format(pricingConfig.endDate, 'M/d/yy')}
                </div>
                <div title={pricingConfig.itemTerms.map((it) => referenceData.termTypes.byId[it.termTypeId].name).join(', ')}>
                  Cost Implications: {pricingConfig.itemTerms.length}
                </div>
                <div className={classes?.headerComments} title={pricingConfig.comments}>
                  Comments: {pricingConfig.comments}
                </div>
              </div>
            );
          },
          costImplications: pricingConfig.itemTerms
            .filter((it) => {
              //if same contract, and term doesn't exist (deleted during current session), don't render the column
              if (it.contract.contractId === contract.contractId) {
                const termExists = contract.terms.contractTermsForItem.some((cti) => cti.uniqueId === it.itemTermUniqueId);
                return termExists;
              }
              return true;
            })
            .map<PG.ICostImplicationConfiguration>((it) => ({
              uniqueId: it.itemTermUniqueId,
              name: referenceData.termTypes.byId[it.termTypeId].name,
              accountingType: PG.AccountingType.CREDIT,
              type: getCostImplicationType(it.termUnitOfMeasureId, referenceData),
              renderHeaderCellContent: () => {
                return (
                  <>
                    <span
                      title={`Internal Contract # ${it.contract.contractId} - ${referenceData.termTypes.byId[it.termTypeId].name} / ${it.itemTermUniqueId}`}
                    >
                      {referenceData.termTypes.byId[it.termTypeId].name}
                    </span>
                  </>
                );
              },
            })),
        };
      } catch (e) {
        console.log('error adding new pricing', e);
        return {} as PG.IPricingColumnConfiguration<PricingGridPricingItem>;
      }
    });
  }, [canModify, contract, referenceData, classes, invokePricingEdit, updatePricing, pricingConfigurationValidationIssues]);

  function getPricingName(pricing: IPricing) {
    return `pricing_${pricing.pricingId ?? pricing.uniqueId}`;
  }

  return {
    pricingItems,
    pricingColumnConfigurations,
  };
}

const useStyles = tss.create({
  root: {},
  headerContents: {
    margin: '15px',
    marginBottom: '10px',
    marginLeft: '20px',
    width: '100%',
  },
  headerComments: {
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerEditBtn: {
    '&&': {
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      color: defaultColors.blue,
      paddingBottom: '5px',
      width: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  suggestedRetail: {
    paddingTop: '8px',
  },
  pricePrefix: {
    paddingRight: '8px',
    fontWeight: 500,
  },
});
